<template>
  <div>
    <!-- Filters -->
    <!-- <app-collapse hover>
      <app-collapse-item title="Filters">
        <VoiceCallLogListFilters
          :status-filter.sync="statusFilter"
          :status-options="voiceCallLogStatusOption"
        />
      </app-collapse-item>
    </app-collapse> -->
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              v-if="
                voiceCallLogsMetaData.length && $can('voice_call_log_delete')
              "
              class="mr-2"
              v-model="isDeleteMultipleCandChecked"
            >
            </b-form-checkbox>
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :searchable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div
              v-if="selectedVoiceCallLogs.length == 0"
              class="d-flex align-items-center justify-content-end"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search To Number / Email"
                />
              </b-input-group>
            </div>
            <b-button
              v-else-if="$can('voice_call_log_delete')"
              class="float-right"
              variant="danger"
              :disabled="voiceCallLogsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ voiceCallLogsDeleteCount }} Voice Call Log</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-form-checkbox-group
        id="checkbox-group"
        v-model="selectedVoiceCallLogs"
        name="selectedVoiceCallLogs"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchVoiceCallLogList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(select)="data">
            <div class="text-nowrap">
              <b-form-checkbox :value="data.item.hashid"> </b-form-checkbox>
            </div>
          </template>
          <!-- Column: User -->
          <template #cell(title)="data">
            <b-media vertical-align="center">
              <b-link
                v-if="$can('voice_call_log_show')"
                :to="{
                  name: 'view-voice-call-log',
                  params: { id: data.item.hashid },
                }"
                class="font-weight-bold d-inline-block text-nowrap"
                v-b-tooltip.hover.v-default
                :title="data.item.title"
              >
                {{ data.item.title | str_limit(15) }}
              </b-link>
              <span
                v-else
                v-b-tooltip.hover.topright.v-primary
                :title="data.item.title"
              >
                {{ data.item.title | str_limit(15) }}
              </span>
            </b-media>
          </template>

          <template #cell(date)="data">
            {{ data.item.date | formatDate }}
          </template>

          <template #cell(time)="data">
            {{ data.item.time | formatTime }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              size="sm"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item
                class="btn-sm p-0"
                @click="viewRecord(data)"
                v-if="$can('voice_call_log_show')"
              >
                <feather-icon icon="EyeIcon" size="13" class="mr-50" />
                <span>View</span>
              </b-dropdown-item>

              <b-dropdown-item
                class="btn-sm p-0"
                @click="confirmDeleteRecord(data)"
                v-if="$can('voice_call_log_delete')"
              >
                <feather-icon icon="TrashIcon" size="13" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-form-checkbox-group>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-if="selectedVoiceCallLogs.length == 0"
              v-model="currentPage"
              :total-rows="totalVoiceCallLogList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <b-button
              v-else-if="$can('voice_call_log_delete')"
              class="float-right"
              variant="danger"
              :disabled="voiceCallLogsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ voiceCallLogsDeleteCount }} Voice Call Log</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import voiceCallLogStoreModule from "../voiceCallLogStoreModule";
import useVoiceCallLogList from "./useVoiceCallLogList";
import VoiceCallLogListFilters from "./VoiceCallLogListFilters.vue";

export default {
  components: {
    VoiceCallLogListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    /* eslint-disable */
    ToastificationContent,
    /* eslint-enable */
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-voice-call-logs";
    const isDeleteMultipleCandChecked = ref(false);
    const selectedVoiceCallLogs = ref([]);
    const voiceCallLogsDeleteCount = ref(0);

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        REFERRAL_APP_STORE_MODULE_NAME,
        voiceCallLogStoreModule
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: "In Progress", code: 0 },
      { label: "Selected", code: 1 },
      { label: "Rejected", code: 2 },
    ];
    const {
      fetchVoiceCallLogList,
      tableColumns,
      perPage,
      currentPage,
      totalVoiceCallLogList,
      voiceCallLogsMetaData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      statusFilter,
      filterByVoiceCallLogUrgencyStatus,
      filterByVoiceCallLogType,
      resolveUserStatusVariant,
      voiceCallLogStatusOption,
    } = useVoiceCallLogList();

    watch(isDeleteMultipleCandChecked, (val) => {
      selectedVoiceCallLogs.value = [];
      if (val) {
        voiceCallLogsMetaData.value.forEach((voice_call_log) => {
          selectedVoiceCallLogs.value.push(voice_call_log.hashid);
        });
      }
    });

    watch(selectedVoiceCallLogs, (val) => {
      voiceCallLogsDeleteCount.value = val.length;
    });

    return {
      // Sidebar
      fetchVoiceCallLogList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      totalVoiceCallLogList,
      // Extra Filters
      statusOptions,
      statusFilter,
      filterByVoiceCallLogUrgencyStatus,
      filterByVoiceCallLogType,
      selectedVoiceCallLogs,
      voiceCallLogsDeleteCount,
      isDeleteMultipleCandChecked,
      resolveUserStatusVariant,
      voiceCallLogStatusOption,
      voiceCallLogsMetaData,
    };
  },
  data() {
    return {};
  },
  created() {
    if (!this.$can("voice_call_log_delete")) {
      this.tableColumns = this.tableColumns.filter(
        (item) => item.key != "select"
      );
    }
  },
  methods: {
    editRecord(data) {
      this.$router
        .push(`/edit/voice-call-log/${data.item.hashid}`)
        .catch(() => {});
    },
    viewRecord(data) {
      this.$router
        .push(`/view/voice-call-log/${data.item.hashid}`)
        .catch(() => {});
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.item.title}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.item.hashid);
        }
      });
    },
    confirmDeleteMultipleRecords() {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${this.selectedVoiceCallLogs.length} Voice Call Log, This cannot be undone.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              "app-voice-call-logs/removeMultipleVoiceCallLogs",
              this.selectedVoiceCallLogs
            )
            .then((res) => {
              this.isDeleteMultipleCandChecked = false;
              this.selectedVoiceCallLogs = [];
              if (res.data.success) {
                this.refetchData();
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Deleted Successfully.",
                //     icon: "BellIcon",
                //     variant: "success",
                //     text: res.data.message,
                //   },
                // });
                this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Deleted Successfully',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
              } else {
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Voice Call Log Deletion failed.",
                //     icon: "BellIcon",
                //     variant: "danger",
                //     text: res.data.message,
                //   },
                // });
                this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Voice Call Log Deletion failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
    refetchVoiceCallLogsData() {
      console.log("Added Voice Call Log");
    },
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-voice-call-logs/removeVoiceCallLog", id)
        .then((res) => {
          if (res.status == "204") {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Voice Call Log Deleted",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: "Voice Call Log Deleted Successfully.",
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Voice Call Log Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: "Voice Call Log Deleted Successfully.",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });

          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Voice Call Log Deletion failed.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Voice Call Log Deletion failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
          self.refetchData();
        })
        .catch((err) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Voice Call Log Deletion failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Voice Call Log Deletion failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          console.error(err);
        });
    },
    openWindow(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
